import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { Link, useParams } from "react-router-dom";
import moment from "moment-timezone";
import AuthContext from "../contexts/auth-context";
import { ClipLoader } from "react-spinners";
import BarChart from '../components/atoms/BarChart';
import OperationalHours from "./operationalHours";
import SpecialHours from "./specialHours";
import gtLogo from "../assets/gtLogo.svg";
import edenLogo from "../assets/edenLogo.png";
import AnalyticsCard from "../pages/AnalyticsCard";

export const HoveredContext = createContext();
export const ExpandedContext = createContext();
export const DataContext = createContext();

moment.tz.setDefault("America/New_York");

function getFormattedTimeAsDouble(dateString) {
  const date = new Date(dateString)
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return hours + minutes / 100;
}

export default function SmallBusinessLocation() {

  const { id } = useParams();

  console.log("COLLECTION: ", id)

  const authCtx = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [currentTime, setCurrentTime] = useState(moment(new Date()).startOf("day").toDate());

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([])
  const [openingHours, setOpeningHours] = useState([])
  const [closingHours, setClosingHours] = useState([])

  const onChangeCurrentTime = (e) => {
    const inputDate = moment(e.target.value, "YYYY-MM-DD", true);

    if (inputDate.isValid()) {
      const today = moment();
      if (inputDate.isAfter(today)) {
        setCurrentTime(today.toDate());
      } else {
        setCurrentTime(inputDate.toDate());
      }
    }
  };

  const getOperationalHours = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://api.queuesapp.com/get-hours-dashboard", {
        headers: {
          "Content-Type": "application/json",
          token: authCtx.token,
        },
        method: "POST",
        body: JSON.stringify({
          collection: id
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setOpeningHours(data["openingHours"]);
      setClosingHours(data["closingHours"]);
      getData(data["openingHours"], data["closingHours"], currentTime);
    } catch (error) {
      getData([], [], currentTime);
      console.error("Failed to fetch operational hours:", error);
    }
  };

  const getData = async (newOpeningHours, newClosingHours, date, attempts = 0) => {

    setData({ occData: [] });
    setChartData([]);

    if (attempts > 30) return;

    try {
      const response = await fetch("https://api.queuesapp.com/get-data", {
        headers: {
          "Content-Type": "application/json",
          token: authCtx.token,
        },
        method: "POST",
        body: JSON.stringify({
          date: moment(date).format("YYYY-MM-DD"),
          collection: id,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.length === 0) {
        const previousDay = moment(date).subtract(1, 'days');
        await getData(newOpeningHours, newClosingHours, previousDay.toDate(), attempts + 1);
      } else {

        if (currentTime !== date) {
          setCurrentTime(date)
        }

        var tempChartData = [];
        const currentDay = new Date().getDay();

        for (let i = 0; i < data.length; i++) {
          const hour = getFormattedTimeAsDouble(data[i]['time'])

          if (newOpeningHours.length === 0 || newClosingHours.length === 0) {
            tempChartData.push({ dateTime: data[i]['time'], mins: data[i]['wait time'] });
          } else if (newOpeningHours[currentDay] <= hour && hour <= newClosingHours[currentDay]) {
            tempChartData.push({ dateTime: data[i]['time'], mins: data[i]['wait time'] });
          }
        }

        setData({ occData: data });
        setChartData(tempChartData);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authCtx.loggedIn) {
      getOperationalHours();
    }
  }, [currentTime]);

  return (
    <>
      {authCtx.loggedIn ? (
        <div>
          <div style={{ backgroundColor: 'rgba(83, 184, 170)', color: 'white', textAlign: 'left', padding: '24px', fontSize: '28px', fontWeight: 'bold' }}>
            Wait Analytics
          </div>

          <div className='p-4' style={{ backgroundColor: 'rgba(83, 184, 170, 0.15)' }}>

            <div className="d-flex mb-2 align-items-center">
              <img
                alt=""
                src={id === 'edenWoodstock' ? edenLogo : gtLogo}
                className="rounded-circle me-3"
                width="50"
                height="50"
                style={{ backgroundColor: 'white', padding: '4px', border: "2px solid black", boxSizing: 'border-box' }}
              />

              <div className="h3">
                {authCtx.authLocations.find(item => item.collection === id).name}
              </div>
            </div>

            <div className='elevated-card' style={{ width: '400px' }}>
              <input
                style={{
                  borderRadius: '10px',
                  border: 'none',
                  outline: 'none',
                  width: '100%',
                }}
                onChange={onChangeCurrentTime}
                type="date"
                className="form-control"
                value={moment(currentTime).format("yyyy-MM-DD")}
                max={moment().format("yyyy-MM-DD")}
              />
            </div>

            {
              chartData.length === 0 ? (
                <p style={{ fontSize: '18px', marginTop: '32px' }}>No data found for the last 30 days.</p>
              ) : (
                null
              )

            }

            <div
              style={{
                width: "100%",
                height: "550px",
                position: 'relative',
                overflowY: 'auto'
              }}
            >
              {loading ? (
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}>
                  <ClipLoader size={150} color="rgba(83, 184, 170)" />
                </div>
              ) :
                (
                  <BarChart data={chartData} />
                )
                 
              }
            </div>

            {
              loading || chartData.length === 0 ? (null) : (<AnalyticsCard data={chartData} />)
            }

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', minWidth: '1200px' }}>
              {
                openingHours.length != 0 && closingHours.length != 0 ? (
                  <div style={{ marginRight: '24px' }}><OperationalHours openingHours={openingHours} closingHours={closingHours} currentId={id} /></div>
                ) : null
              }
              <div> <SpecialHours currentId={id} /></div>
            </div>

          </div>

        </div>
      ) : (
        <div className="p-4">
          <Link to={"/auth"}>
            Not Authorized. Please Log-In with given credentials or contact
            support.
          </Link>
        </div>
      )}
    </>
  );
}