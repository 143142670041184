import React, { useState, useContext, useEffect } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AuthContext from "../contexts/auth-context";
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import dayjs from 'dayjs';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';


const formatDoubleToTimeString = (doubleTime) => {

    if (doubleTime > 24 || doubleTime < 0) return "Invalid"

    var hours = Math.floor(doubleTime);
    const minutes = Math.round((doubleTime - hours) * 100);

    if (minutes >= 60 || minutes < 0) return "Invalid"

    var formattedHours = hours.toString().padStart(2);
    const formattedMinutes = minutes.toString().padStart(2, 0);

    const meridiem = hours >= 12 ? "PM" : "AM";

    if (meridiem === "PM") {
        hours -= 12
        formattedHours = hours.toString().padStart(2);
    }

    return `${formattedHours}:${formattedMinutes} ${meridiem}`;
};

const SpecialHours = ({ currentId }) => {

    const [specialHours, setSpecialHours] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [openingTime, setOpeningTime] = useState(null);
    const [closingTime, setClosingTime] = useState(null);
    const [reason, setReason] = useState('');

    const [addHoursMode, setAddHoursMode] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const [isSpecialHour, setIsSpecialHour] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const [isOpen24Hours, setIsOpen24Hours] = useState(false);
    const [showSubmitHours, setShowSubmitHours] = useState('');

    const authCtx = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.queuesapp.com/get-special-hours-dashboard", {
                    headers: {
                        "Content-Type": "application/json",
                        dashboardToken: authCtx.token,
                    },
                    method: "POST",
                    body: JSON.stringify({
                        collection: currentId
                    }),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                var specialDays = [];
                for (var i = 0; i < data.length; i++) {
                    const newDay = new SpecialDay(
                        data[i].startDate,
                        data[i].endDate,
                        formatDoubleToTimeString(parseFloat(data[i].openingHour)),
                        formatDoubleToTimeString(parseFloat(data[i].closingHour)),
                        data[i].label
                    );
                    specialDays.push(newDay);
                }
                setSpecialHours(specialDays);
            } catch (error) {
                console.error("Failed to fetch special hours:", error);
                // Optionally, handle the error by setting state, showing error messages, etc.
            }
        };

        fetchData();
    }, [currentId]); // Make sure the dependencies list is correctly set for your use case



    const resetCheckBoxes = () => {
        setIsSpecialHour(false)
        setIsClosed(false)
        setIsOpen24Hours(false)
    }


    const handleSpecialHourChange = (event) => {
        setIsSpecialHour(event.target.checked);
        if (event.target.checked) {
            setIsClosed(false);
            setIsOpen24Hours(false);
        }
    };

    const handleClosedChange = (event) => {
        setIsClosed(event.target.checked);
        if (event.target.checked) {
            setIsSpecialHour(false);
            setIsOpen24Hours(false);
        }
    };

    const handleOpen24HoursChange = (event) => {
        setIsOpen24Hours(event.target.checked);
        if (event.target.checked) {
            setIsSpecialHour(false);
            setIsClosed(false);
        }
    };


    async function submitHours() {

        setShowSubmitHours(false)

        setErrorMessage('')

        for (var i = 0; i < specialHours.length; i++) {
            if (parseFloat(specialHours[i].open) > parseFloat(specialHours[i].close)) {
                setErrorMessage(specialHours[i].day + " opening hours cannot be later than closing hours.")
                return
            }
        }

        var specialDays = []

        for (var i = 0; i < specialHours.length; i++) {
            specialDays.push({
                "startDate": specialHours[i].startDate,
                "endDate": specialHours[i].endDate,
                "label": specialHours[i].reason,
                "openingHour": specialHours[i].opening,
                "closingHour": specialHours[i].closing,
            })
        }

        const response = await fetch("https://api.queuesapp.com/set-special-hours-dashboard", {
            headers: {
                "Content-Type": "application/json",
                dashboardToken: authCtx.token,
            },
            method: "POST",
            body: JSON.stringify({
                collection: currentId,
                email: authCtx.email,
                newHours: specialDays
            }),
        });

        const responseData = await response.json();

        setEditMode(false)
    };


    const removeSpecialDay = (indexToRemove) => {
        setShowSubmitHours(true);
        setSpecialHours(currentSpecialHours =>
            currentSpecialHours.filter((_, index) => index !== indexToRemove)
        );
    };


    const addSpecialDay = () => {

        const formattedStartDate = selectedStartDate ? selectedStartDate.format('MM/DD/YYYY') : null;
        const formattedEndDate = selectedEndDate ? selectedEndDate.format('MM/DD/YYYY') : null;

        if (formattedStartDate == null) {
            setErrorMessage("Please select a start date!");
        } else if (formattedEndDate == null) {
            setErrorMessage("Please select an end date!");
        } else if (formattedEndDate < formattedStartDate) {
            setErrorMessage("The end date cannot be earlier than the start date!");
        } else if (reason.length === 0) {
            setErrorMessage("Please specify the occasion!");
        } else if (!isClosed && !isOpen24Hours && (openingTime == null || closingTime == null)) {
            setErrorMessage("Please set opening/closing times!");
        } else if (specialHours.some((day) => day.startDate === formattedStartDate)) {
            setErrorMessage("This date already has special hours set.");
        } else {
            var formattedOpeningTime = openingTime ? openingTime.format('h:mm A') : null;
            var formattedClosingTime = closingTime ? closingTime.format('h:mm A') : null;

            if (formattedClosingTime < formattedOpeningTime) {
                setErrorMessage("Closing time cannot be earlier than opening time.");
                return;
            }

            if (isClosed) {
                formattedOpeningTime = '1:00 AM'
                formattedClosingTime = '1:00 AM'
            }

            if (isOpen24Hours) {
                formattedOpeningTime = '0:00 AM'
                formattedClosingTime = '12:00 PM'
            }

            const newDay = new SpecialDay(formattedStartDate, formattedEndDate, formattedOpeningTime, formattedClosingTime, reason);
            setSpecialHours([...specialHours, newDay]);

            setEditMode(true);
            setShowSubmitHours(true);
            setSelectedStartDate(null);
            setSelectedEndDate(null);
            setOpeningTime(null);
            setClosingTime(null);
            setReason('');
            setErrorMessage('');
        }
    };


    return (
        <div className='elevated-card'>
            <div className='hours-title queuesGreen'>
                Special Hours

                {
                    editMode ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '8px' }}>
                        <button
                            style={{ marginRight: '8px' }}
                            className="btn border-1"
                            onClick={() => setEditMode(false)}
                        >
                            Cancel
                        </button>


                        {
                            showSubmitHours ? <button
                                className="btn border-1"
                                onClick={() => submitHours()}
                            >
                                Submit
                            </button> : null
                        }

                    </div>
                        : < IconButton aria-label="edit" onClick={() => setEditMode(true)}>
                            <EditIcon />
                        </IconButton>
                }
            </div>

            {specialHours.map((item, index) => (

                <div key={index} style={{
                    display: 'flex',
                    backgroundColor: 'white',
                    alignItems: 'center',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    borderTop: '1px solid #d3d3d3'
                }}>
                    {
                        editMode ? (
                            <button
                                className="btn border-1"
                                onClick={() => removeSpecialDay(index)}
                            >
                                X
                            </button>
                        ) : null
                    }

                    <div style={{ width: '300px', paddingRight: '16px', paddingLeft: editMode ? '16px' : '0px' }}>
                        {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(item.startDate))}
                        {item.startDate !== item.endDate && (
                            <>
                                {' - '}
                                {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(item.endDate))}
                            </>
                        )}
                    </div>

                    <div style={{ width: '175px', paddingRight: '16px' }}>
                        {
                            item.opening === item.closing ? 'Closed' :
                                item.opening === "0:00 AM" && item.closing === "12:00 PM" ? 'Open 24 Hours' :
                                    `${item.opening} - ${item.closing}`
                        }
                    </div>
                    <div>{item.reason}</div>
                </div>
            ))}

            {
                addHoursMode ? <div>
                    <div style={{ display: 'flex', gap: '8px', padding: '8px', background: 'white', }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{ maxWidth: '175px' }}>
                                <DatePicker
                                    slotProps={{ textField: { size: 'small', label: 'Start Date' } }}
                                    value={selectedStartDate}
                                    onChange={setSelectedStartDate}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDate={dayjs()}
                                />
                            </div>

                            <div style={{ maxWidth: '175px' }}>
                                <DatePicker
                                    slotProps={{ textField: { size: 'small', label: 'End Date' } }}
                                    value={selectedEndDate}
                                    onChange={setSelectedEndDate}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDate={dayjs()}
                                />
                            </div>

                            {
                                isSpecialHour ? (
                                    <div style={{ maxWidth: '275px', display: 'flex' }}>

                                        <TimePicker
                                            slotProps={{ textField: { size: 'small', } }}
                                            value={openingTime}
                                            onChange={setOpeningTime}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                        <div style={{ width: '8px' }} />
                                        <TimePicker
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={closingTime}
                                            onChange={setClosingTime}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                ) : null
                            }


                        </LocalizationProvider>
                        <div style={{ maxWidth: '150px' }}>
                            <TextField
                                size="small"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                placeholder="Reason"
                            />
                        </div>
                        <button className="btn border-1" onClick={() => {
                            setAddHoursMode(false)
                            resetCheckBoxes()
                            setErrorMessage('')
                        }}>X</button>
                        <button className="btn border-1" onClick={addSpecialDay}>✓</button>
                    </div>
                    <div style={{ display: 'flex', padding: '8px', background: 'white', }}>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: '#53b8aa', // checked color
                                    },
                                }}
                                    checked={isClosed} onChange={handleClosedChange} />}
                                label="Closed"
                            />
                            <FormControlLabel
                                control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: '#53b8aa', // checked color
                                    },
                                }} checked={isOpen24Hours} onChange={handleOpen24HoursChange} />}
                                label="Open 24 Hours"
                            />
                            <FormControlLabel
                                control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: '#53b8aa', // checked color
                                    },
                                }} checked={isSpecialHour} onChange={handleSpecialHourChange} />}
                                label="Special Hour"
                            />
                        </FormGroup>
                    </div>

                </div> :
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '8px', background: 'white', }}>
                        <button
                            className="btn border-1"
                            onClick={() => setAddHoursMode(true)}
                        >
                            Add Special Day
                        </button>
                    </div>

            }

            {errorMessage && <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>{errorMessage}</div>}
        </div>
    );


};

export default SpecialHours;

class SpecialDay {
    constructor(startDate, endDate, opening, closing, reason) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.opening = opening;
        this.closing = closing;
        this.reason = reason;
    }
}

