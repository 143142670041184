import React, { useEffect, useState } from 'react';
import moment from "moment-timezone";

moment.tz.setDefault("America/New_York");

const AnalyticsCard = ({ data }) => {

  const [timeOver9Mins, setTimeOver9Mins] = useState(0);

  function calculateTotalTimeOver9Mins() {

    var timeOver9MinsTemp = 0;

    for (var i = 0; i < data.length; i++) {
      if (data[i].mins > 9) {
        timeOver9MinsTemp++;
      }
    }

    setTimeOver9Mins(timeOver9MinsTemp)
  }

  const determinePeakTime = () => {
    let peakWait = 0;
    let peakTime = data[0].dateTime;

    for (var i = 0; i < data.length; i++) {
      if (data[i].mins > peakWait) {
        peakWait = data[i].mins;
        peakTime = data[i].dateTime;
      }
    }

    const date = moment(peakTime);

    return (
      <span>
        <span style={{ color: '#53b8aa' }}> {date.format("h:mm a")} {' - '}</span>
        <span style={{ color: peakWait > 9 ? '#B22626' : '#53b8aa', marginLeft:'4px' }}>
          {peakWait} mins
        </span>
      </span>
    );
  };

  function findLowestAverageWaitTime() {
    let lowestAverage = Number.MAX_SAFE_INTEGER;
    let lowestAverageSpan = null;

    console.log("DATA: ", data)

    if (data.length < 30) {
      return;
    }

    for (let i = 0; i <= data.length - 30; i++) {
      let sum = 0;
      for (let j = i; j < i + 30; j++) {
        sum += data[j].mins;
      }
      const average = sum / 30;

      if (average < lowestAverage) {
        lowestAverage = average;
        const startDateTime = new Date(data[i].dateTime);
        const endDateTime = new Date(startDateTime.getTime() + 30 * 60000);
        const startTimeStr = startDateTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        const endTimeStr = endDateTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

        lowestAverageSpan = `${startTimeStr} - ${endTimeStr}`;
      }
    }

    return lowestAverageSpan
  }


  useEffect(() => {
    if (data !== null) {
      calculateTotalTimeOver9Mins()
      determinePeakTime()
      findLowestAverageWaitTime()
    }

  }, []);

  return (
    <div className='elevated-card' style={{ display: 'flex' }}>
      <div style={{ alignContent: 'start' }}>
        <p style={{ fontSize: 20 }}>Total Time &gt; 9 mins:</p>
        <div className='analytics-mini-card'>
          {
            timeOver9Mins > 9 ? (<p className='analytics-text queuesRed'>
              {timeOver9Mins} mins
            </p>) : (<p className='analytics-text queuesGreen'>
              {timeOver9Mins} mins
            </p>)
          }
        </div>
      </div>
      <div style={{ alignContent: 'start' }}>
        <p style={{ fontSize: 20 }}>Peak Wait Time:</p>
        <div className='analytics-mini-card'>
          <p className='analytics-text queuesRed'>
            {determinePeakTime()}
          </p>
        </div>

      </div>
      <div style={{ alignContent: 'start' }}>
        <p style={{ fontSize: 20 }}>Lowest Wait Period:</p>
        <div className='analytics-mini-card'>
          <p className='analytics-text queuesGreen'>
            {findLowestAverageWaitTime()}
          </p>
        </div>

      </div>
    </div>
  );
};

export default AnalyticsCard;


